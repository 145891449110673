<template>
  <div class="header">
    <h2 class="logo">
      LOGO HERE
    </h2>
  </div>
  <div class="login">
    <el-card>
      <h2>Login</h2>
      <el-form
        ref="form"
        class="login-form"
        :model="model"
        :rules="rules"
        @submit.prevent="login"
      >
        <div v-if="showLoginError">
          <el-alert
            title="Username or password is invalid!"
            effect="dark"
            type="error"
          />
        </div>
        <el-form-item prop="email">
          <el-input
            v-model="model.email"
            placeholder="Email"
            prefix-icon=""
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="model.password"
            placeholder="Password"
            type="password"
            prefix-icon=""
          />
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            class="login-button"
            type="primary"
            native-type="submit"
            block
          >
            Login
          </el-button>
        </el-form-item>
        <a
          class="forgot-password"
          href="https://oxfordinformatics.com/"
        >Forgot Password?</a>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import {ElCard, ElForm, ElFormItem, ElButton, ElInput, ElAlert} from "element-plus"
    export default {
        name: "Login",
      components: {
        ElCard, ElForm, ElFormItem, ElButton, ElInput, ElAlert
      },
        data() {
            return {
				showLoginError: false,
                validCredentials: {
                    email: "",
                    password: ""
                },
                model: {
                    email: "",
                    password: ""
                },
                loading: false,
                rules: {
                    email: [
                        {
                            required: true,
                            message: "Email is required",
                            trigger: "blur"
                        },
                    ],
                    password: [
                        { required: true, message: "Password is required", trigger: "blur" },
                        {
                            min: 4,
                            message: "Password length should be at least 5 characters",
                            trigger: "blur"
                        }
                    ]
                }
            };
        },
        methods: {
            simulateLogin() {
                return new Promise(resolve => {
                    setTimeout(resolve, 800);
                });
            },
            async login() {
                let valid = await this.$refs.form.validate();
                if (!valid) {
                    return;
                }
                this.loading = true;
                await this.doLogin();
                // await this.simulateLogin();
                // this.loading = false;
                // if (
                //     this.model.email === this.validCredentials.email &&
                //     this.model.password === this.validCredentials.password
                // ) {
                //     this.$message.success("Login successfull");
                // } else {
                //     this.$message.error("Username or password is invalid");
                // }
            },
            async doLogin() {
              this.$store.dispatch("auth/login", this.model).then(
                  () => {
                    this.$router.push("/dashboard");
                  },
                  (error) => {
                    this.loading = false;
                    this.showLoginError = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                  }
              )
            }
        }
    }
</script>

<style scoped lang="scss">
    .login {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-button {
        width: 100%;
        margin-top: 40px;
    }
    .login-form {
        width: 290px;
    }
    .forgot-password {
        margin-top: 10px;
    }
	$teal: rgb(0, 124, 137);
    .el-button--primary {
        background: $teal;
        border-color: $teal;

    &:hover,
    &.active,
    &:focus {
         background: lighten($teal, 7);
         border-color: lighten($teal, 7);
     }
    }
    .login .el-input__inner:hover {
        border-color: $teal;
    }
    .login .el-input__prefix {
        background: rgb(238, 237, 234);
        left: 0;
        height: calc(100% - 2px);
        left: 1px;
        top: 1px;
        border-radius: 3px;
    .el-input__icon {
        width: 30px;
    }
    }
    .login .el-input input {
        padding-left: 35px;
    }
    .login .el-card {
        padding-top: 0;
        padding-bottom: 30px;
    }
    h2 {
        font-family: "Open Sans";
        letter-spacing: 1px;
        font-family: Roboto, sans-serif;
        padding-bottom: 20px;
    }
    a {
        color: $teal;
        text-decoration: none;
    &:hover,
    &:active,
    &:focus {
         color: lighten($teal, 7);
     }
    }
    .login .el-card {
        width: 340px;
        display: flex;
        justify-content: center;
    }
</style>
